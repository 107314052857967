import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { Row, Col, Image } from "react-bootstrap"

//images imports
import BorgfeldA1 from "./../images/borgfeld as an archisculpture, risograph/fp-borgfeld as an archisculpture, risograph.jpg"
import BorgfeldA2 from "./../images/borgfeld as an archisculpture, risograph/borgfeld as an archisculpture, risograph1.jpg"
import BorgfeldA3 from "./../images/borgfeld as an archisculpture, risograph/borgfeld as an archisculpture, risograph2.jpg"
import BorgfeldA4 from "./../images/borgfeld as an archisculpture, risograph/borgfeld as an archisculpture, risograph3.jpg"

const BorgfeldArchisculpture = ({location, id}) => (
    <Layout>
        <Seo title="Borgfeld as an archisculpture" description="Borgfeld as an archisculpture. By Manon Lambeens" />
            
        <Row className="p-0">
            <Col xxl={12} xl={12} lg={12} md={12} s={12} xs={12} className="pe-1 ps-1 mt-1">
              <h1 className="text-uppercase fs-4 text-dark">borgfeld as an archisculpture</h1>
                <p className="textAbout">
                I have a great interest in architecture and the spatial aspect. I transformed the Borgfeld font into an archisculpture. I made some risoprints of these archisculptures. 
                </p>
            </Col>
        </Row>
        <Row className="text-center p-0">
            <Col xxl={6} xl={6} lg={6} md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
                <Image src={BorgfeldA2} fluid alt="Borgfeld as an archisculpture, Manon Lambeens"/>
            </Col>
            <Col xxl={6} xl={6} lg={6} md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
                <Image src={BorgfeldA1} fluid alt="Borgfeld as an archisculpture, Manon Lambeens"/>
            </Col>
      </Row>
      <Row className="text-center p-0">
        <Col xxl={6} xl={6} lg={6} md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
         <Image src={BorgfeldA3} fluid alt="Borgfeld as an archisculpture, Manon Lambeens"/>
        </Col>
        <Col xxl={6} xl={6} lg={6} md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
         <Image src={BorgfeldA4} fluid alt="Borgfeld as an archisculpture, Manon Lambeens"/>
        </Col>
      </Row>
    </Layout>
)

export default BorgfeldArchisculpture;